@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

@font-face {
    font-family: 'font_r';
    src: url('fonts/Aller_Rg.ttf');
}

@font-face {
    font-family: 'font_b';
    src: url('fonts/Aller_Bd.ttf');
}

@font-face {
    font-family: 'font_i';
    src: url('fonts/Aller_It.ttf');
}

@font-face {
    font-family: 'font_l';
    src: url('fonts/Aller_Lt.ttf');
}

body {
    margin: 0;
    font-family: 'font_b';
    font-display: swap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

.bold {
    font-family: 'font_b';
}
.regular {
    font-family: 'font_r';
}
.light {
    font-family: 'font_l';
}

.link {
    transition: 0.2s all;
    cursor: pointer;
}
.link:hover {
    opacity: 0.6;
}

/* width */
.custom_scrollbar::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.custom_scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 30px;
}

/* Handle */
.custom_scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 30px;
}

/* Handle on hover */
.custom_scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.blue-shade {
    background-color: #1d4ed8;
}
